// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-angebot-kontaktlinsen-tsx": () => import("./../src/pages/angebot/kontaktlinsen.tsx" /* webpackChunkName: "component---src-pages-angebot-kontaktlinsen-tsx" */),
  "component---src-pages-angebot-sehhilfen-tsx": () => import("./../src/pages/angebot/sehhilfen.tsx" /* webpackChunkName: "component---src-pages-angebot-sehhilfen-tsx" */),
  "component---src-pages-angebot-situationsgerechtes-sehen-tsx": () => import("./../src/pages/angebot/situationsgerechtes-sehen.tsx" /* webpackChunkName: "component---src-pages-angebot-situationsgerechtes-sehen-tsx" */),
  "component---src-pages-angebot-sonnenbrillen-tsx": () => import("./../src/pages/angebot/sonnenbrillen.tsx" /* webpackChunkName: "component---src-pages-angebot-sonnenbrillen-tsx" */),
  "component---src-pages-angebot-tsx": () => import("./../src/pages/angebot.tsx" /* webpackChunkName: "component---src-pages-angebot-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-dienstleistungen-augenvorsorge-tsx": () => import("./../src/pages/dienstleistungen/augenvorsorge.tsx" /* webpackChunkName: "component---src-pages-dienstleistungen-augenvorsorge-tsx" */),
  "component---src-pages-dienstleistungen-brillenwerkstatt-tsx": () => import("./../src/pages/dienstleistungen/brillenwerkstatt.tsx" /* webpackChunkName: "component---src-pages-dienstleistungen-brillenwerkstatt-tsx" */),
  "component---src-pages-dienstleistungen-gewerbe-und-industrieberatung-tsx": () => import("./../src/pages/dienstleistungen/gewerbe-und-industrieberatung.tsx" /* webpackChunkName: "component---src-pages-dienstleistungen-gewerbe-und-industrieberatung-tsx" */),
  "component---src-pages-dienstleistungen-heimberatung-tsx": () => import("./../src/pages/dienstleistungen/heimberatung.tsx" /* webpackChunkName: "component---src-pages-dienstleistungen-heimberatung-tsx" */),
  "component---src-pages-dienstleistungen-screening-test-tsx": () => import("./../src/pages/dienstleistungen/screening-test.tsx" /* webpackChunkName: "component---src-pages-dienstleistungen-screening-test-tsx" */),
  "component---src-pages-dienstleistungen-sehtest-tsx": () => import("./../src/pages/dienstleistungen/sehtest.tsx" /* webpackChunkName: "component---src-pages-dienstleistungen-sehtest-tsx" */),
  "component---src-pages-dienstleistungen-services-tsx": () => import("./../src/pages/dienstleistungen/services.tsx" /* webpackChunkName: "component---src-pages-dienstleistungen-services-tsx" */),
  "component---src-pages-dienstleistungen-tsx": () => import("./../src/pages/dienstleistungen.tsx" /* webpackChunkName: "component---src-pages-dienstleistungen-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-schwarz-optik-tsx": () => import("./../src/pages/schwarz-optik.tsx" /* webpackChunkName: "component---src-pages-schwarz-optik-tsx" */)
}

